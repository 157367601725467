@import "../../colors";

.h1-title {
  color: $bg800;
  font-size: 70px;
  margin-left: 40px;
  margin-right: 20px;
}

.text-content{
  position: relative;
  z-index: 1;
}

.square{
  width: 300px;
  height: 300px;
  border-radius: 4px;
  background-color: white;
  position: absolute;
  bottom: 0;
  transform: rotate(45deg);
  z-index: 0;

  &-1{
    bottom: -60%;
    background-color: $bg850;
    left: 30%;
    animation: square1 60s infinite;
  }
  &-2{
    bottom: -40%;
    left: 0%;
    background-color: $bg800;
    animation: square2 60s  infinite;
  }

}

@keyframes square2 {
  0%{
    bottom: -40%;
    left: 0%;
  }

  50%{
    bottom: -10%;
    left: 30%;
  }

  100%{
    bottom: -40%;
    left: 0%;
  }
}

@keyframes square1 {
  0%{
    bottom: -60%;
    left: 30%;
  }

  50%{
    bottom: -90%;
    left: 45%;
  }

  100%{
    bottom: -60%;
    left: 30%;
  }
}

.showcase-card {
  border-radius: 6px;
  background-color: $bg875;
  min-height: 300px;
  padding: 30px 30px 30px 50px;
  margin-left: 12.5%;
  width: 40%;
  min-width: 210px;
  border: $bg1200 1px solid;
  position: relative;
  overflow: hidden;
  &-right{
    margin-left: 10px;
  }
}

img.hero-image{
  width: 100%;
}

.centered {
  place-items: center;
  place-content: center;
  display: grid;
}
.hero{
  height: 100%;
  width: 100%;
  background:linear-gradient(45deg, rgba(34,37,200,1) 0%, rgba(34,37,200,1) 50%, rgba(240,116,0,1) 50%, rgba(240,116,0,1) 100%);
}


@media only screen and (max-width: 700px)  {
    .h1-title {
      display: none;
    }

  .showcase-card{
    width: 100%;
    margin-right: 12.5%;
    margin-left: 12.5%;
  }
}

