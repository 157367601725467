@import "colors";

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400,600&display=swap');

* {
  font-family: 'Source Sans Pro', sans-serif;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  padding: 1%;
  background-color: $bg1100;
  color: $bg200;
}

h1 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.spacer {
  margin-top: 40px
}

.black-text {
  color: $bg700;
}

.spacer-large {
  margin-top: 80px
}

.margin-25 {
  margin-right: 12.5%;
  margin-left: 12.5%;
}

.margin-20 {
  margin-right: 10%;
  margin-left: 10%;
}

.flex.flex-col {
  flex-direction: column;
}

.flex {
  display: flex;
  flex-direction: row;
  flex-basis: 0;

  &-1 {
    width: 100%
  }

  &-2 {
    width: 50%
  }

  &-3 {
    width: 33.33333%
  }

  &-4 {
    width: 25%
  }

  &-5 {
    width: 20%
  }

  &-6 {
    width: 16.66666%
  }
}

@media only screen and (max-width: 1000px) {
  .flex.flex-sm-col {
    flex-direction: column;
  }
}
