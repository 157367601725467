@import "../../../../colors";

.website-screen-box {
  height: 300px;
  width: 400px;
  padding-left: 40px;
  padding-right: 40px;
}

.website-screen {
  position: relative;
  width: 320px;
  height: 180px;
  background-color: white;
  place-self: center;
  border-radius: 4px;
  border-left: 2px $bg700 solid;
  border-right: 2px $bg700 solid;
  border-bottom: 2px $bg700 solid;
  border-top: 2px $bg700 solid;
}

.website-header {
  background-color: $bg700;
  height: 10px;
  width: 100%;
  font-size: 8px;
  justify-content: end;
}

.website-header > * {
  margin-left: 3px;
  margin-right: 3px;
}

.float-web {
  background-color: $A50;
  box-shadow: 1px 2px 10px -3px #3B3B3B;
}

.website-screen > div {
  position: absolute;
  border-left: 1px solid $bg700;
  border-bottom: 2px solid $bg700;
}

#screen1 {
  left: 0;
  width: 50px;
  height: 95%;
  box-shadow: 4px 5px 10px -3px #3B3B3B;
  animation: screen1 infinite 10s;
  border-left: 1px solid $bg700;
  border-bottom: 2px solid $bg700;
  padding: 5px;
  font-size: 5px;
}

#screen1 > p {
  padding-bottom: 10px;
}

#screen2 {
  height: 25px;
  width: 25px;
  background: linear-gradient(to right, #fff6e5, #ffffe5, #e5f2e5, #e5ffff, #e5e5ff, #fdf2fd);
  right: 4px;
  top: 15px;
  font-size: 25px;
  animation: screen2 15s infinite;
  border-radius: 3px;

}

#screen3 {
  height: 25px;
  width: 25px;
  background: $A50;
  right: 4px;
  top: 45px;
  font-size: 25px;
  animation: screen3 15s infinite;
  border-radius: 3px;
}

#screen5 {
  width: 200px;
  top: 18px;
  left: 70px;
  height: 50px;
  font-size: 5px;
  padding-left: 8px;
  padding-top: 4px;
  animation: screen5 4s none infinite;
}

#screen6 {
  width: 200px;
  top: 78px;
  left: 70px;
  height: 50px;
  font-size: 5px;
  padding-left: 8px;
  padding-top: 4px;
  animation: screen6 3s none infinite;
  animation-delay: -1s;
}

#screen7 {
  display: flex;
  background-color: transparent;
  bottom: 15px;
  left: 200px;
  border:none;
  box-shadow: none;
  animation: screen7 infinite none 9s;
}

#screen7 > div {
  height: 10px;
  width: 30px;
  margin-left: 5px;
  box-shadow: 4px 5px 7px -3px #3B3B3B;
}

#button_web1 {
  background-color: $fail;

}

#button_web2 {
  background-color: $success;
}

@keyframes screen7 {
  0%, 100% {
    bottom: 15px;
    left: 200px;
  }
  50% {
    bottom: 13px;
    left: 205px;
  }
}

@keyframes screen5 {
  0%, 100% {
    left: 70px;
    top: 18px;
  }
  50% {
    left: 72px;
    top: 22px;
  }
}

@keyframes screen6 {
  0%, 100% {
    left: 70px;
    top: 78px;
  }
  50% {
    left: 72px;
    top: 80px;
  }
}

@keyframes screen3 {
  0%, 100% {
    right: 4px;
    top: 45px;
  }

  50% {
    right: -6px;
    top: 55px;
  }
}

@keyframes screen2 {
  0%, 100% {
    right: 4px;
    top: 15px;
  }

  50% {
    right: -6px;
    top: 25px;
  }
}

@keyframes screen1 {
  0%, 100% {
    left: 0;
    top: 10px;
  }

  50% {
    left: -10px;
    top: 15px;
  }
}
