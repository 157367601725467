@import "../../../../colors";
.card {
  height: 300px;
  width: 187.5px;
  background-color: white;
}

.isometric {
  transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg);
  margin-left: 90px;
  margin-right: 90px;
}

.micro-nav {
  width: 100%;
  height: 40px;
  background-color: $A700;
  transform-style: preserve-3d;
}

.content {
  background-color: white;
  padding: 7px;
  font-size: 8px;
}

.blue-text {
  color: blue;
}

.box {
  margin-top: 20px;
  height: 75px;
  background-color: $S50;
}

.right {
  padding-left: 12.5%;
  margin-top: 0;

  &-right {
    margin-right: 12.5%;
  }
}

.button {
  height: 17px;
  width: 30px;
  margin-top: 50px;
  background-color: $success;
  float: right;
  margin-right: 40px;
}

.drop-shadow {
  box-shadow: -20px 30px 35px -20px #3B3B3B;
}

.float {
  transform: translate3d(20px, -30px, 24px);
  border-left: #102A43 solid 3px;
  border-bottom: #102A43 solid 2px;
  animation: floating 3s infinite;
  animation-timing-function: ease-in-out;
}

.start-slow {
  animation-delay: -1s;
}

.start-slowest {
  animation-delay: -2s;
}

.float:hover {
  transform: translate3d(30px, -40px, 24px) !important;
  animation-play-state: paused;
}

.hamburger-container {
  margin-left: 10px;
  height: 100%;
  color: #222222;
  display: grid;
  align-content: center;
}


@keyframes floating {
  0% {
    transform: translate3d(20px, -30px, 24px);
  }

  6.25% {
    transform: translate3d(21px, -31px, 24px);
  }

  13.5% {
    transform: translate3d(22px, -32px, 24px);
  }

  19.75% {
    transform: translate3d(23px, -33px, 24px);
  }

  25% {
    transform: translate3d(24px, -34px, 24px);
  }

  31.25% {
    transform: translate3d(25px, -35px, 24px);
  }

  37.5% {
    transform: translate3d(26px, -36px, 24px);
  }

  43.75% {
    transform: translate3d(27px, -37px, 24px);
  }

  50% {
    transform: translate3d(28px, -38px, 24px);
  }

  56.25% {
    transform: translate3d(27px, -37px, 24px);
  }

  63.5% {
    transform: translate3d(26px, -36px, 24px);
  }

  69.75% {
    transform: translate3d(25px, -35px, 24px);
  }

  75% {
    transform: translate3d(24px, -34px, 24px);
  }

  81.25% {
    transform: translate3d(23px, -33px, 24px);

  }

  87.5% {
    transform: translate3d(22px, -32px, 24px);

  }

  93.75% {
    transform: translate3d(21px, -31px, 24px);

  }

  100% {
    transform: translate3d(20px, -30px, 24px);
  }
}
