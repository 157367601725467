$bg50: #F7F7F7;
$bg100: #E1E1E1;
$bg200: #CFCFCF;
$bg300: #B1B1B1;
$bg400: #9E9E9E;
$bg500: #7E7E7E;
$bg600: #626262;
$bg700: #515151;
$bg800: #3B3B3B;
$bg850: #303030;
$bg875: #292929;
$bg900: #222222;
$bg1000: #1e1e1e;
$bg1100: #191919;
$bg1200: #141414;
$bg1300: #0c0c0c;

$A50: #e4f1fb;
$A100: #bfdcf7;
$A200: #98c7f1;
$A300: #73b1eb;
$A400: #5ba0e7;
$A500: #4a91e2;
$A600: #4583d4;
$A700: #3e72c1;
$A800: #3861af;
$A900: #2d448f;

$S50: #e1f9f9;
$S100: #b3f1f1;
$S200: #81e8e9;
$S300: #4adde2;
$S400: #0dd5dd;
$S500: #00cdd9;
$S600: #00bcc6;
$S700: #00a6ac;
$S800: #009195;
$S900: #006d69;

$success: #00897B;
$fail: #5e1914;
